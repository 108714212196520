<template>
  <div id="monetic-request-list-table-component">
    <section class="section-container">
      <!-- <div
        v-if="list.length > 0 && category"
        class="upload-button-container"
        :class="[category === 'PREPAID_CARD_BLOCK' ? 'flex-end' : '']"
      >
        <generate-button v-if="['PREPAID_CARD_CREATE', 'CASHBACK_PAID'].includes(category)" :category="category" /> -->
      <div v-if="list.length > 0 && category" class="upload-button-container">
        <upload-button :category="category" />
      </div>

      <div v-loading="isLoading" class="list">
        <template v-if="list.length > 0" class="list">
          <el-table :data="partialTable" empty-text="Pas de demandes" @sort-change="onSort">
            <el-table-column prop="date" label="Date" sortable="custom" width="100">
              <template slot-scope="scope">
                {{ dateToStr(scope.row.date) }}
              </template>
            </el-table-column>

            <el-table-column prop="name" label="Nom du fichier" />

            <el-table-column v-if="hasStatus" width="220" prop="status" label="Statut">
              <template slot-scope="scope">
                <p v-html="scope.row.status"></p>
              </template>
            </el-table-column>

            <el-table-column width="230" align="center" label="Action">
              <template slot-scope="scope">
                <el-button
                  v-if="scope.row.actionButton"
                  size="small"
                  class="el-button el-button--danger"
                  v-html="scope.row.actionButton"
                  :disabled="scope.row.actionData.disabled || false"
                  @click="$emit('click', scope.row.actionData)"
                ></el-button>
                <p v-else-if="scope.row.actionMessage" v-html="scope.row.actionMessage"></p>
              </template>
            </el-table-column>
          </el-table>

          <el-pagination
            hide-on-single-page
            layout="prev, pager, next"
            :current-page="currentPage"
            :total="list.length"
            :page-size="displayCount"
            @current-change="num => currentPage = num"
            @prev-click="currentPage--"
            @next-click="currentPage++"
          />
        </template>

        <p v-else class="empty">
          - Aucune demande à traiter -
        </p>
      </div>
    </section>
  </div>
</template>

<script>
import UploadButton from '@/components/Monetic/UploadButton.vue'
// import GenerateButton from '@/components/Monetic/GenerateButton.vue'

export default {
  name: 'TableFiles',

  components: {
    UploadButton
    // GenerateButton
  },

  props: {
    category: {
      type: String,
      validator (category) {
        return [
          'PREPAID_CARD_CREATE',
          'CASHBACK_PAID',
          'PREPAID_CARD_BLOCK'
        ]
          .indexOf(category) > -1
      }
    },

    hasStatus: {
      type: Boolean,
      default: true
    },

    list: {
      type: Array,
      default () {
        return []
      }
    },

    isLoading: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      currentPage: 1,
      displayCount: 10,
      orderByDateAsc: true
    }
  },

  computed: {
    partialTable () {
      const list = [...this.list]
      const order = this.orderByDateAsc ? 1 : -1
      list.sort((a, b) => (new Date(b.date).getTime() - new Date(a.date).getTime()) * order)

      const min = (this.currentPage - 1) * this.displayCount
      const max = min + this.displayCount

      return list.slice(min, max)
    }
  },

  methods: {
    onSort ({ prop = 'date', order = null }) {
      if (prop === 'date') {
        if (order === 'ascending' || order === null) {
          this.orderByDateAsc = true
        } else if (order === 'descending') {
          this.orderByDateAsc = false
        }
      }
    },

    dateToStr (time) {
      const options = {
        year: '2-digit',
        month: 'numeric',
        day: 'numeric'
      }

      return new Date(time).toLocaleDateString('fr-FR', options)
    },

    dateTimeToStr (time) {
      const date = new Date(time)

      return [date.getHours(), date.getMinutes()].join(':')
    },

    fileStatusToStr (status, date, percentage = 0) {
      if (status === 'validated') {
        return `téléchargé le ${this.dateToStr(date)} à ${this.dateTimeToStr(date)}`
      } else if (status === 'pending') {
        return `traitement en cours`
      } else if (status === 'reset') {
        return `traitement OK - 100% traité`
      } else if (status === 'refused') {
        return `traitement KO - ${percentage}% traité`
      }

      return 'statut inconnu'
    }
  }
}
</script>

<style lang="scss" scoped>
.upload-button-container {
  display: flex;
  justify-content: space-between;
  width: 100%;

  &.flex-end {
    justify-content: flex-end;
  }
}

::v-deep .red {
  color: #DF0D36;
}

::v-deep .green {
  color: #0BB864;
}

::v-deep .el-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 25px 0;
}

::v-deep .file-icon {
  font-size: 20px;
  line-height: 0;
  vertical-align: middle;
}

.empty {
  text-align: center;
  padding: 100px 0;
}

.list {
  clear: both;
}
</style>
