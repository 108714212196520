<template>
  <div v-loading="isLoading">
    <TableFiles
      :is-loading="isLoading"
      :category="category"
      :list="list"
      @click="onClick"
    />
  </div>
</template>

<script>
import MoneticPage from '@/mixins/MoneticPage'

export default {
  name: 'BlockTotalCard',

  mixins: [
    MoneticPage
  ],

  data () {
    return {
      action: 'media/getMedia',
      category: 'PREPAID_CARD_BLOCK'
    }
  }
}
</script>
