<template>
  <section class="section-clubistes">
    <el-menu class="menu-left" :default-active="$route.name" @select="fixFastClick">
      <el-menu-item index="createTotalCard" class="first-el-menu-item">
        <router-link :to="{ name: 'createTotalCard' }" class="link">
          Creation de TotalEnergies Card
        </router-link>
      </el-menu-item>

      <el-menu-item index="claimCashback">
        <router-link :to="{ name: 'claimCashback' }" class="link">
          Versement de Cashback
        </router-link>
      </el-menu-item>

      <el-menu-item index="banTotalCard">
        <router-link :to="{ name: 'banTotalCard' }" class="link">
          Blocage de TotalEnergies Card
        </router-link>
      </el-menu-item>
    </el-menu>

    <div class="slot">
      <slot></slot>
    </div>
  </section>
</template>

<script>
export default {
  name: 'MoneticBody',

  props: {
    totalCardRequests: {
      type: Array,
      default () { return [] }
    },

    claimCashbackRequests: {
      type: Array,
      default () { return [] }
    },

    totalCardBanRequests: {
      type: Array,
      default () { return [] }
    }
  },

  methods: {
    // If you click faster the route don't change
    // This fix add a test (from menu) to force route to change
    fixFastClick (name) {
      this.$nextTick(() => {
        if (name !== this.$route.name) {
          this.$router.push({ name })
            .catch(error => console.error(error.message))
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.link {
  text-decoration: none;
}

.section-clubistes {
  display: flex;
  .slot {
    flex: 1;
  }
}
</style>
