<template>
  <div v-loading="isLoading" id="upload-button-component">
    <input @change="upload" ref="input" class="upload-input" type="file" name="upload-file" id="upload-file" accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">

    <label class="upload-button" for="upload-file">
      <i class="el-icon-upload2 file-icon"></i> Déposer fichiers retours
    </label>
  </div>
</template>

<script>
export default {
  name: 'UploadButton',

  props: {
    category: {
      type: String,
      required: true,
      validator (category) {
        return ['PREPAID_CARD_CREATE', 'CASHBACK_PAID', 'PREPAID_CARD_BLOCK'].indexOf(category) > -1
      }
    }
  },

  data () {
    return {
      isLoading: false
    }
  },

  methods: {
    upload (data) {
      const files = this.$refs.input.files
      if (files.length > 0) {
        const file = files[0]

        const options = {
          file,
          category: this.category
        }

        this.isLoading = true

        this.$store.dispatch('file/getUploadFileUrl', options)
          .then(fileData => {
            return this.$store.dispatch('file/uploadFile', { file, url: fileData.storageUploadUrl, category: this.category })
          })
          .then(() => this.$message({
            message: `Le fichier a bien été envoyé`,
            type: 'success'
          }))
          .catch(error => {
            this.$message.error(this.$t(error.message))
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.upload-input {
  display: none;
}

.upload-button {
  display: block;
  background-color: rgba(22, 65, 148, 0.08);
  border: 1px dashed #164194;
  border-radius: 4px;
  width: 272px;
  height: 50px;
  color: #164194;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 50px;
  text-align: center;
  cursor: pointer;
}

.file-icon {
  font-size: 30px;
  vertical-align: middle;
}
</style>
