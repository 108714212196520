<template>
  <div id="monetic-head-component" class="monetic-head-component">
    <p class="monetic-head-text">
      <slot></slot>
    </p>
  </div>
</template>

<script>
export default {
  name: 'MoneticHead'
}
</script>

<style lang="scss" scoped>
.monetic-head-component {
  margin: 25px 0;
}

.monetic-head-text {
  color: #3E3E3E;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}
</style>
