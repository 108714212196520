<template>
  <div>
    <div class="container">
      <MoneticHead>
        Demandes à traiter
      </MoneticHead>

      <MoneticBody>
        <router-view />
      </MoneticBody>
    </div>
  </div>
</template>

<script>
import MoneticHead from '@/components/Monetic/MoneticHead.vue'
import MoneticBody from '@/components/Monetic/MoneticBody.vue'

export default {
  components: {
    MoneticHead,
    MoneticBody
  }

}
</script>

<style lang="scss" scoped>
.informations-page {
  .el-form {
    margin-bottom: 25px;
  }
}
</style>
