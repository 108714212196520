import TableFiles from '@/components/TableFiles.vue'

export default {
  name: 'MoneticPage',

  components: {
    TableFiles
  },

  data () {
    return {
      isLoading: false,
      listRaw: [],
      action: '',
      category: ''
    }
  },

  computed: {
    list () {
      return this.listRaw.map(this.format)
    }
  },

  created () {
    this.update()
  },

  methods: {

    getStatus (media) {
      if (media.isTodo) {
        return ''
      } else {
        const getUnit = (count = 0) => count > 1 ? `lignes traitées` : `ligne traitée`
        if (media.isPending) {
          return 'traitement en cours'
        } else if (media.isOk) {
          return `<span class="green">${media.rows.processed}/${media.rows.total} ${getUnit(media.rows.total)}</span>`
        } else if (media.isKo) {
          return `<span class="red">${media.rows.processed}/${media.rows.total} ${getUnit(media.rows.processed)}</span>`
        }
      }
      return ''
    },

    getActions (media) {
      let action = {
        actionButton: null,
        actionMessage: null
      }
      if (media.isTodo) {
        action.actionButton = `<i class="el-icon-download file-icon"></i> Télécharger`
        action.actionData = {
          file: media.file,
          category: this.category
        }
      } else {
        action.actionMessage = 'téléchargé le ' +
          this.dateToStr(media.downloaded) + ' à ' +
          this.dateTimeToStr(media.downloaded)
      }

      return action
    },

    format (media) {
      const data = {
        id: media.id,
        date: media.date,
        name: media.file.name,
        status: this.getStatus(media),
        ...this.getActions(media)
      }

      return data
    },

    downloadFile (file, category) {
      const options = {
        category,
        file
      }

      this.isLoading = true
      this.$store.dispatch('file/downloadFile', options)
        .then(() => this.$message({
          message: 'Le fichier ' + file.name + ' a bien été téléchargé',
          type: 'success'
        }))
        .catch(error => {
          this.$message.error(this.$t(error.message))
        })
        .finally(() => {
          this.isLoading = false
          this.update()
        })
    },

    onClick ({ file, category }) {
      this.downloadFile(file, category)
    },

    update () {
      this.isLoading = true
      this.$store.dispatch(this.action, { category: this.category })
        .then(list => {
          this.listRaw = list
        })
        .catch(error => {
          this.$message.error(this.$t(error.message))
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    dateToStr (time) {
      const options = {
        year: '2-digit',
        month: 'numeric',
        day: 'numeric'
      }
      return new Date(time).toLocaleDateString('fr-FR', options)
    },

    dateTimeToStr (time) {
      const date = new Date(time)
      return [date.getHours(), date.getMinutes()].join(':')
    }
  }
}
